<template>
  <v-card height="100%" color="backgroundColor">
    <div :class="$vuetify.breakpoint.smAndUp ? ' mx-7' : 'mx-4'">
      <v-btn
        name="Retour"
        class="ma-6"
        text
        icon
        color="primary"
        @click="backTo()"
      >
        <v-icon size="60px">mdi-arrow-left-circle-outline</v-icon>
      </v-btn>
      <div class="Welcometitle primary--text titleFontlg">
        Validation de la commande pour l'établissement :
        <span class="secondary--text">{{ idEtablissement }}</span>
      </div>
      <div class="text-center primary--text titleFontmd mb-3">
        {{ InfoEtab.nom }} {{ InfoEtab.cp }} {{ InfoEtab.ville }}
      </div>

      <v-card
        elevation="0"
        color="backgroundColor"
        tile
        class="elevation-1 mx-5"
        v-if="!finalResumeToggle"
      >
        <v-row :justify="'end'" class="mr-5">
          <v-switch
            v-model="ChangeVue"
            inset
            :label="labelActif"
            v-if="!finalResumeToggle"
          />
        </v-row>
        <!--Resumé-->
        <div
          class="mt-8"
          tile
          elevation="0"
          v-for="element in panierAffiche"
          :key="element.codeReference"
        >
          <v-row
            justify="space-between"
            class="mx-6"
            :class="$vuetify.breakpoint.smAndUp ? ' text-h5' : 'text-h6'"
          >
            <v-col cols="12" sm="4" class="primary--text text-uppercase pa-0">
              {{ element.codeReference }}
            </v-col>
            <v-spacer />
            <v-col cols="6" sm="4" class="primary--text text-right pa-0">
              Qté: {{ element.subTotal }}
            </v-col>
            <v-col cols="6" sm="3" class="primary--text text-right pa-0">
              {{ element.priceSubTotal }} €
            </v-col>
          </v-row>
          <v-divider class="mx-4 my-3"></v-divider>
          <v-row class="mt-0">
            <v-col
              v-for="item in element.bibliotheques"
              :key="item.info1"
              class="pa-0 mx-2 my-2"
              style="max-width: 320px; min-width: 320px"
            >
              <v-card
                height="100%"
                width="100%"
                elevation="5"
                class="mb-0 mx-0 pa-4"
              >
                <v-row>
                  <v-col cols="4" class="pa-0" align-self="center">
                    <v-img
                      height="150px"
                      width="100%"
                      v-if="item.type === 'livre' && item.imgCouverture"
                      :src="item.imgCouverture"
                      class=""
                    ></v-img>
                    <!-- icone affichée si pas d'image -->
                    <v-icon
                      color="primary"
                      v-if="item.type === 'livre' && !item.imgCouverture"
                    >
                      mdi-book
                    </v-icon>
                  </v-col>
                  <v-col cols="8" class="pa-0 pl-2 d-flex flex-column">
                    <v-sheet>
                      <span class="primary--text caption"
                        >Titre :
                        <span class="secondary--text">
                          {{ item.name }}
                        </span>
                      </span>
                      <br />
                      <span class="primary--text caption"
                        >Ref. Divalto : {{ item.refOffreDivalto }}</span
                      ><br />
                      <span class="primary--text caption"
                        >Prix unitaire TTC: {{ item.prix }} €</span
                      ><br />
                      <v-icon v-if="!item.canOrder" color="warning">
                        mdi-alert
                      </v-icon>
                    </v-sheet>
                    <v-sheet class="secondary--text text-right mt-auto"
                      >Qté: {{ item.quantity }}
                    </v-sheet>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
          </v-row>
        </div>
        <!-- total -->
        <v-row
          justify="space-between"
          class="mx-6 mt-8"
          :class="$vuetify.breakpoint.smAndUp ? ' text-h5' : 'text-h6'"
        >
          <v-col cols="12" md="4" class="primary--text text-uppercase pa-0">
            Total commande:
          </v-col>
          <v-spacer />
          <v-col cols="6" md="4" class="primary--text text-right pa-0">
            Total:
            <span class="secondary--text">{{ totaux.totalQuantity }} </span>
            ouvrages
          </v-col>
          <v-col cols="6" md="3" class="primary--text text-right pa-0">
            <span class="secondary--text">{{ totaux.totalPrice }} </span> €
          </v-col>
        </v-row>
        <v-divider class="mx-4 my-3"></v-divider>
        <!-- validation -->

        <v-row
          :justify="$vuetify.breakpoint.xs ? 'center' : 'end'"
          class="my-5"
          :class="$vuetify.breakpoint.xs ? 'pr-0' : 'pr-3'"
        >
          <!--     @TODO remove disable button       -->
          <NextButton
            width="220"
            color="primary"
            text="Valider ma commande"
            @clicked="valideOrder"
            :loading="loading"
          />
        </v-row>
        <v-dialog v-model="confirmOrderDialog" hide-overlay persistent width="400" >
          <v-card>
            <v-card-text class="pt-3 font-weight-bold">
              La commande contient des références qui n'existe plus dans les catalogues éditeurs, veuillez confirmer la commande
            </v-card-text>
            <v-card-actions>
              <v-btn color="primary" @click="confirmOrder">CONFIRMER</v-btn>
              <v-btn depressed color="error" @click="cancelOrder">ANNULER</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog v-model="loading" hide-overlay persistent width="400" >
          <v-card color="primary" dark>
            <v-card-text class="pt-3 font-weight-bold">
              Merci de patienter cette opération peut prendre plusieurs minutes
              <v-progress-linear
                indeterminate
                color="white"
                class="mb-1 mt-3"
              ></v-progress-linear>
            </v-card-text>
          </v-card>
        </v-dialog>

      </v-card>
      <FinalResume v-if="finalResumeToggle" :dataAPI="responceAPI" />
    </div>
  </v-card>
</template>
<script>
import { mapGetters } from "vuex";
import NextButton from "../components/Utility/NextButton.vue";
import FinalResume from "../components/OrderVueComponent/FinalResume.vue";
import OrderService from "../services/OrderService";
import AdminService from "../services/AdminService";

export default {
  components: {
    NextButton,
    FinalResume,
  },
  data() {
    return {
      InfoEtab: {},
      ChangeVue: false,
      finalResumeToggle: false,
      responceAPI: [],
      loading: false,
      confirmOrderDialog: false,
    };
  },
  computed: {
    idEtablissement() {
      return this.$route.params.id;
    },
    ...mapGetters("order", ["totaux", "panier", "panierParEditeur"]),
    panierAffiche() {
      if (this.ChangeVue == true) {
        return this.panierParEditeur;
      } else {
        return this.panier;
      }
    },
    labelActif() {
      if (this.ChangeVue == true) {
        return "Vue par éditeurs";
      } else {
        return "Vue par classes";
      }
    },
  },
  mounted() {
    AdminService.getEtablissementByID(this.idEtablissement).then((element) => {
      this.InfoEtab = element.data;
    });
  },

  methods: {
    backTo() {
      this.$router.push({ path: `/admin/order/${this.idEtablissement}` }).catch((error) => {
        if (error.name !== "NavigationDuplicated") {
          throw error;
        }
      });
    },
    confirmOrder() {
      this.confirmOrderDialog = false
      this.loading = true;
      this.panierParEditeur.map((panier) => {
        panier.utilisateur = this.$store.state.user.identifiant;
      });
      OrderService.sendOrder(this.panierParEditeur, this.idEtablissement)
        .then(({ data }) => {
          this.responceAPI = data;
          this.finalResumeToggle = true;
          this.loading = false;
          this.responceAPI.forEach((element, id) => {
            this.responceAPI[id].date = new Date(
              Date.parse(element.date)
            ).toLocaleString("fr");
            this.responceAPI.total = this.responceAPI[id].bibliotheques.reduce(
              (acc, value) => {
                return acc + value.prix * value.quantity;
              },
              0
            );
          });
        })
        .catch((err) => {
          if (err.response.status === 499) {
            this.$store.dispatch("setSnackbar", {
              color: "success",
              text: "La commande est en cours de traitement... Veuillez revenir dans quelques minutes",
            });
            this.$router.push({ path: `/admin/historique?search=${this.idEtablissement}` }).catch((error) => {
              if (error.name !== "NavigationDuplicated") {
                throw error;
              }
            });
          } else {
            if (err) {
              this.$store.dispatch("setSnackbar", {
                color: "error",
                text: "Une erreur est survenue.",
              });
              this.loading = false;
              this.dialogRedirection = false;
            }
          }
        });
    },
    cancelOrder() {
      this.confirmOrderDialog = false
    },
    valideOrder() {
      let biblios = this.panier.flatMap((p) => p.bibliotheques)
      this.confirmOrderDialog = biblios.some((b) => !b.canOrder)
      if(!this.confirmOrderDialog) {
        this.confirmOrder()
      }
    },
  },
};
</script>
 <style scoped>
.Welcometitle {
  text-align: center;
  margin-bottom: 16px;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
}
.titleFontlg {
  font-size: 28px;
}
</style>

