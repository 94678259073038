var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"elevation-0 ma-4 pa-4",attrs:{"footer-props":{itemsPerPageOptions: _vm.itemsPerPageOptions,itemsPerPageText: _vm.itemsPerPageText},"header-props":{ sortByText: "Trier par"},"headers":_vm.headers,"items":_vm.dataAPITotal,"item-key":"numeroCommande","expanded":_vm.expanded,"hide-default-footer":"","show-expand":""},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"item.prix",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.totalPriceCommande)+" € ")]}},{key:"expanded-item",fn:function(ref){
var item = ref.item;
var headers = ref.headers;
return [_c('td',{staticClass:"ma-0 pa-2",attrs:{"colspan":headers.length}},[_c('v-data-table',{staticClass:"ma-0 pa-0",attrs:{"footer-props":{itemsPerPageOptions: _vm.itemsPerPageOptions,itemsPerPageText: _vm.itemsPerPageText},"header-props":{ sortByText: "Trier par"},"headers":_vm.headers2,"items":item.bibliotheques,"hide-default-footer":"","disable-sort":""},scopedSlots:_vm._u([{key:"item.prix",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.prix)+" € ")]}},{key:"item.total",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.total)+" € ")]}}],null,true)},[_c('template',{slot:"no-data"},[_c('p',{staticClass:"secondary--text"},[_vm._v(" "+_vm._s(item.errorMessage)+" ")])])],2)],1)]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }