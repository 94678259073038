<template>
  <div>

    <v-data-table
        :footer-props="{itemsPerPageOptions,itemsPerPageText}"
        :header-props="{ sortByText: `Trier par`}"
        :headers="headers"
        :items="dataAPITotal"
        item-key="numeroCommande"
        class="elevation-0 ma-4 pa-4"
        :expanded.sync="expanded"
        hide-default-footer
        show-expand
    >
      <template v-slot:[`item.prix`]="{ item }">
        {{ item.totalPriceCommande }} €
      </template>
      <template v-slot:expanded-item="{item, headers}">
        <td
            :colspan="headers.length"
            class="ma-0 pa-2"
        >
          <v-data-table
              :footer-props="{itemsPerPageOptions,itemsPerPageText}"
              :header-props="{ sortByText: `Trier par`}"
              :headers="headers2"
              :items="item.bibliotheques"
              hide-default-footer
              class="ma-0 pa-0"
              disable-sort
          >
            <template v-slot:[`item.prix`]="{ item }">
              {{ item.prix }} €
            </template>
            <template v-slot:[`item.total`]="{ item }">
              {{ item.total }} €
            </template>
            <template slot="no-data">
              <p class="secondary--text">
                {{ item.errorMessage }}
              </p>
            </template>
          </v-data-table>
        </td>
      </template>
    </v-data-table>
  </div>
</template>
<script>
export default {
  name: "FinalResume",
  props: {dataAPI: Array},
  data() {
    return {
      itemsPerPageOptions: [10, 25, 50, 1000],
      itemsPerPageText: `Lignes par page`,
      expanded: [],
      singleExpand: true,
      headers: [
        {text: "Numéro de commande", value: "numeroCommande"},
        {text: "Editeur", value: "editeur"},
        {text: "Date", value: "date"},
        {text: "Status", value: "status"},
        {text: "Prix", value: "prix"},
        {text: "Résumer", value: "data-table-expand"},
      ],
      headers2: [
        {
          text: "Nom",
          value: "name",
          align: "center",
          class: "lightprimary primary--text ",
        },
        {
          text: "refOffreDivalto",
          value: "refOffreDivalto",
          class: "lightprimary primary--text",
        },
        {text: "Prix", value: "prix", class: "lightprimary primary--text "},
        {
          text: "Quantité",
          value: "quantity",
          class: "lightprimary primary--text",
        },
        {
          text: "Total",
          value: "total",
          class: "lightprimary primary--text",
        },
      ],
    };
  },
  computed: {
    dataAPITotal() {
      let finalArray = this.dataAPI;
      finalArray.forEach((element, id) => {
        let totalPriceCommande = 0;
        finalArray[id].bibliotheques.map((book) => {
          let totalPriceBook = book.prix * book.quantity;
          return (totalPriceCommande = totalPriceCommande + totalPriceBook);
        });
        finalArray[id].totalPriceCommande = totalPriceCommande;
      });
      return finalArray;
    },
  },
};
</script>
